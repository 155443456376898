import request from '@/plugins/request'

// 结算列表
export function settleList(data = {}){
  return request({
    url: '/api/order/parent/getSettlementList',
    method: 'POST',
    data,
  })
}

//获取结算明细
export function fetchSettle(itemId){
  if(!itemId) return new Error('itemId is null')
  return request({
    url: '/api/order/detailed/getSettlementDetailed?orderId='+itemId,
    method: 'GET',
  })
}

// 结算/未结算金额
export function fetchMoney(data = {}){
  return request({
    url: '/api/order/parent/getSettlementMoney',
    method: 'POST',
    data: data,
  })
}



export default {
  //结算列表
  settleList,
  fetchSettle,
  fetchMoney,
}